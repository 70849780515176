<template>
  <v-dialog
    persistent
    v-model="dialog"
    :overlay-color="companyData.companyColor ? companyData.companyColor : ''"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style="width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center tw-items-center tw-self-end  tw-cursor-pointer "
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div v-if="status === 'inputCode'"
        class=" tw-flex card tw-p-10 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 300px"
      >
        <!-- <icons name="confirm-delete" :color="companyColor" /> -->
        <p
          class="title  tw-pt-3"
          :style="{ color: companyData.companyColor ? companyData.companyColor : '' }"
        >
          Enter Traveller's Code
        </p>
        <v-col sm="12">
          <ValidationProvider
            name="Booking Code"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="23813343"
                solo
                hide-details
                type="text"
                required
                v-model="bookingCode"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
        <div
          class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div tw-mt-5"
        >
          <v-btn
            text
            class="second-btn"
            @click="$emit('bookingAction',bookingCode)"
            :style="{ background: companyData.companyColor ? companyData.companyColor : '', color: 'white' }"
            :loading="doneLoading"
          >
            Enter
          </v-btn>
        </div>
      </div>
      <div v-if="status === 'displayCode'"
           class=" tw-flex card tw-p-10 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 300px"
      >

        <img src="@/assets/thumbsup.svg" alt="thumbs up" />
        <p
            class="title  tw-pt-3"
            style="color: #4F4F4F;">
          Booking Code
        </p>
        <p class="title code  tw-pt-3 tw-cursor-pointer"
            :style="{ color: companyData.companyColor ? companyData.companyColor : '' }"
        @click="copyCode(description)">
          {{description}} <v-icon color="green" v-if="copied">mdi-check</v-icon>
        </p>
        <p class="code-desc">{{message}}</p>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import check from "@/assets/confirm-delete.svg";
import {mapGetters} from "vuex";
// import Icons from "../../components/transportCompany/Icons";

export default {
  name: "BookingCodeModal",
  // components: { Icons },
  props: {
    dialog: Boolean,
    doneLoading: Boolean,
    icon: [String],
    title: [String],
    message: {
      type: [String],
      default: "Your details have been successfully saved, click code to copy."
    },
    description:[String],
    additionalText: [String],
    userName: [String],
    bottomText: [String],
    link: {
      type: String,
      required: false,
      default: "Dashboard",
    },
    status:{
      type:[String],
      default: "inputCode"
    }
  },
  data() {
    return {
      check,
      companyColor: "#004AAD",
      copied: false,
      bookingCode: "",
    };
  },
  computed:{
    ...mapGetters("companyProfile",["companyData"])
  },
  methods:{
    async copyCode (code) {
      try {
        await navigator.clipboard.writeText(code);
        this.copied = true
      } catch($e) {
        console.log("")
      }
    }
  },
  async created() {

  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (min-width: 768px) {
    width: 30% !important;
  }
}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;
}
.title {
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 30px !important;
  line-height: 36px;
  text-align: center;
}
.desc {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.place-top {
  position: absolute;
  top: 15%;
  /* top: 50px; */
}

.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    /* margin-left: 20px; */
  }
}
.code-desc{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #4F4F4F;
}
</style>
