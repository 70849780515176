<template>
      <div class="tw-w-full look">
        <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start lg:tw-items-center tw-py-5">
          <p :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
             class="passenger">Passenger <span v-if="index > 0" class="or"> {{index+1}} </span>  Details</p>
          <p  class="tw-pt-8 md:tw-pt-0"><span :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
                    class="use-code tw-cursor-pointer" @click="closeModal">Use Traveller’s Code </span><span v-if="index > 0" class="or"> OR </span>
            <span v-if="index > 0" :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
                  class="use-code tw-cursor-pointer" @click="getPrimaryTraveller">Use Primary Details</span></p>
        </div>

            <div class="tw-flex flipping">
              <v-col sm="12" lg="6"  md="6" class="data left">
                <p class="form-header">First Name</p>
                <ValidationProvider name="First name" rules="required" v-slot="{classes, errors}">
                  <div :class="classes">
                    <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                                  v-model="traveller.firstName"></v-text-field>
                    <span> {{errors[0]}} </span>
                  </div>
                </ValidationProvider>

              </v-col>
              <v-col sm="12" lg="6"  md="6" class="data right">
                <p class="form-header">Last Name</p>
                <ValidationProvider name="Last name" rules="required" v-slot="{classes, errors}">
                  <div :class="classes">
                    <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                                  v-model="traveller.lastName"></v-text-field>
                    <span> {{errors[0]}} </span>
                  </div>
                </ValidationProvider>

              </v-col>
            </div>
            <div class="tw-flex flipping">
              <v-col sm="12" lg="6"  md="6" class="data left">
                <p class="form-header"> Phone Number</p>
                <phone-number :phone-number-exist="traveller.phoneNumber" @getNumber="getTravellerNumber" color="#004aad" />
              </v-col>
              <v-col sm="12" lg="6"  md="6" class="data right">
                <p class="form-header">Email</p>
                <ValidationProvider name="Email" rules="required" v-slot="{classes, errors}">
                  <div :class="classes">
                    <v-text-field solo placeholder="example@gmail.com" hide-details type="email"
                                  color="#004aad" v-model="traveller.email"></v-text-field>
                    <span> {{errors[0]}} </span>
                  </div>

                </ValidationProvider>
              </v-col>

            </div>

            <div class="next-of-kin-details">
              <p :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
                 class="nof">Next Of Kin Details</p>

              <div class="tw-flex flipping">
                <v-col sm="12" lg="6"  md="6" class="data left">

                  <ValidationProvider name="Next of kin first name" rules="required" v-slot="{classes, errors}">
                    <p class="form-header">First Name</p>
                    <div :class="classes">
                      <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                                    v-model="traveller.nextOfKinFirstName"></v-text-field>
                      <span> {{errors[0]}} </span>
                    </div>
                  </ValidationProvider>

                </v-col>
                <v-col sm="12" lg="6"  md="6" class="data right">
                  <p class="form-header">Last Name</p>
                  <ValidationProvider name="Next of kin last name" rules="required" v-slot="{classes, errors}">
                    <div :class="classes">
                      <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                                    v-model="traveller.nextOfKinLastName"></v-text-field>
                      <span> {{errors[0]}} </span>
                    </div>
                  </ValidationProvider>

                </v-col>
              </div>
              <div class="tw-flex flipping">
                <v-col sm="12" lg="6"  md="6" class="data left">
                  <p class="form-header"> Phone Number</p>
                  <phone-number :phone-number-exist="traveller.nextOfKinPhoneNumber" @getNumber="getNextOfKinNumber" color="#004aad" />
                </v-col>
                <v-col sm="12" lg="6"  md="6" class="data right">
                  <p class="form-header">Email (Optional)</p>
                  <ValidationProvider name="Next of kin email" rules="required" v-slot="{classes, errors}">
                    <div :class="classes">
                      <v-text-field solo placeholder="example@gmail.com" hide-details type="email"
                                    color="#004aad" v-model="traveller.nextOfKinEmail"></v-text-field>
                      <span> {{errors[0]}} </span>
                    </div>
                  </ValidationProvider>
                </v-col>

              </div>
            </div>

        <booking-code-modal
            :dialog="dialog"
            @close="closeModal"
            @bookingAction="enterBookingCode"
            :doneLoading="getCodeLoading"/>
      </div>
</template>

<script>
import PhoneNumber from '@/components/reuseables/PhoneNumber.vue'
import BookingCodeModal from "@/components/reuseables/booking/BookingCodeModal";
import {getPassengerDetail} from "@/services/api/APIService";
import {mapGetters} from "vuex";


export default {
    name:"CustomerDetails",
  components: {BookingCodeModal, PhoneNumber },
  props:{
      travellerDetail:{},
      index:[Number]
  },
  data() {
    return {
      traveller:{},
      dialog:false,
      getCodeLoading: false,
    }
  },
  watch:{
    traveller: {
        handler : function (val) {
          if (this.index === 0) {
            sessionStorage.setItem("primaryTraveller",JSON.stringify(val))
          }
             this.tripAndTravellerDetails.seats.forEach((seat,index) => {
                if (index === this.index){
                  seat.travellerDetail = val
                }
              })
            this.$store.dispatch("booking/updateTripAndTravellerDetails",this.tripAndTravellerDetails)
           sessionStorage.setItem("tripDetails",JSON.stringify(this.tripAndTravellerDetails))
        }
      },
    immediate: true,
    deep : true
  },
  computed:{
      ...mapGetters("booking",["booking", "tripAndTravellerDetails"]),
      ...mapGetters("companyProfile",["companyData"])
  },
  methods: {
    getNextOfKinNumber(number) {
      this.traveller.nextOfKinPhoneNumber = number
    },
    getTravellerNumber(number) {
      this.traveller.phoneNumber = number
    },
    closeModal(){
      this.dialog = !this.dialog
    },
    getPrimaryTraveller(){
      let primaryTraveller = JSON.parse(sessionStorage.getItem("primaryTraveller"))
      if (Object.keys(primaryTraveller).length){
        this.traveller = primaryTraveller
      }
      else {
        this.$displaySnackbar({
          message: "Please fill in primary traveller's details",
          success: false,
        });
      }
    },
    async  enterBookingCode(code) {
      this.getCodeLoading = true
      if (code && code.length === 10){
        await getPassengerDetail(code).then(res =>{
          this.traveller = res.data
          this.getCodeLoading = false
          this.closeModal()
        })
            .catch((err) => {
              this.getCodeLoading = false
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
      }
      else {
        this.getCodeLoading = false
        this.$displaySnackbar({
          message: "Not a valid code",
          success: false,
        });
      }

    },
  },

  created() {
      if (Object.keys(this.tripAndTravellerDetails).length){
        this.tripAndTravellerDetails.seats.forEach((detail,index) => {
          if (index === this.index){
            this.traveller = detail.travellerDetail
          }
        })
      }
  }
}
</script>

<style lang="scss" scoped>
.look{
    padding: 5% 7% !important;

  @media screen and (max-width: 1024px) {
    padding: 0 !important;
  }
}
  .flipping {
    @media (max-width:960px) {
      flex-direction: column;
    }
  }
  .form-header{
    font-family: var(--fontInter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 0.5rem;

    color: rgba(0, 17, 39, 0.73);
  }
    .code {
        font-family: var(--fontInter);
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #004AAD;
            @media (max-width:576px) {
         font-size: 12px;
        }
    }

    ::v-deep .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
        background: transparent !important;
    }

    .left {
        margin-right: 30px !important;
    }

    .next-of-kin-details {
        padding: 100px 0 !important;
    }

    .nof {
        font-family: var(--fontInter);
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #004AAD;
    }

    .data {
        padding: 20px 0 !important;
    }

    label {
        font-family: var(--fontInter);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: rgba(0, 17, 39, 0.73);

    }

.passenger{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 500;
  font-size: 18px!important;
  line-height: 22px;
  color: #004AAD;
}

.use-code{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px!important;
  line-height: 17px;
  color: #004AAD;

}
.or{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #263238;
}
</style>